/* LiveBanner.css */
.live-banner {
  width: 100%;
  background-color: #9147ff; 
  color: white;
  overflow: hidden;
  max-height: 50px;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
}

.live-banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .live-banner-content {
    font-size: 16px;
    height: 50px;
  }
}

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-container {
  position: relative;
  width: 80%;
  height: 80%;
  max-width: 1000px;
}

.close-video {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}