@import url('https://cdn.jsdelivr.net/npm/geist-font@latest/dist/fonts.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

*::selection {
  background: rgba(0,0,0,0);
}

html,body {
  background-color: #000;
  color: #fff;
  min-height: 100dvh;
  padding: 0;
  -webkit-touch-callout: none !important;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100%;
  /* padding: 15px; */
  gap: 64px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .app-container {
    gap: 0;
  }
}

.logo {
  width: 120px;
  top: 48px;
  z-index: 5;
}

@media (max-height: 768px) {
  .logo {
    top: 28px;
  }
}

/* ===== DiggCounter Component ===== */
.digg-container {
  position: relative;
  width: 120px;
  height: 136px;
  cursor: pointer;
  transition: transform 0.3s ease;
  overflow: visible;
}

/* Add responsive adjustments */
/* 
@media (max-width: 768px) {
  .digg-container {
    width: 90px;
    height: 100px;
  }
}
  */

.app-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

@media (max-width: 768px) {
  .app-content {
    gap: 16px;
  }
}

.digg-container:hover {
  transform: translateY(-3px);
}

.gradient-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1; /* Ensure the container is above other elements */
}

.gradient-effect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  z-index: 0;
  filter: blur(48px);
  -webkit-filter: blur(48px);
  opacity: 0;
  background: linear-gradient(90deg, rgba(255, 0, 212, 0.8), rgba(0, 179, 255, 0.8), rgba(0, 240, 184, 0.8), rgba(255, 0, 212, 0.8));
  background-size: 300% 100%;
  animation: gradient-animation 4s ease infinite;
}

/* Animation for the glow expansion on click */
@keyframes expandGlow {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.glow-expand {
  animation: expandGlow 2s ease-in-out;
}

.digg-box {
  background: radial-gradient(circle at center 33%, rgba(255,255,255,1) 20%, rgba(254,233,162,1) 50%);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

/* Animated border for Digg card */
.digg-border {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 17px; /* Slightly larger than the box */
  z-index: 1;
  border: 1px solid transparent;
  background: linear-gradient(90deg, #ff00d4, #00b3ff, #00f0b8, #ff00d4) border-box;
  background-size: 300% 100%;
  animation: gradient-animation 4s ease infinite;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.counter {
  font-family: 'Geist', sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 13px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE/Edge */
}

/* Number Flow specific styling */
.number-flow-counter {
  color: #000;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
  font-family: 'Geist', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE/Edge */
}

/* Number Flow digit styling */
.number-flow-counter span {
  min-width: 15px; /* Ensure consistent width */
  text-align: center;
  margin: 0 -1px; /* Tighter spacing between digits */
}

/* Style for the K suffix */
.number-flow-counter span:last-child {
  margin-left: 0;
}

.digg-button-container {
  width: 95%;
  margin-bottom: -20px;
}

.digg-button-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.bounce {
  animation: bounce 0.5s ease forwards;
}

/* ===== Page Content ===== */
h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 44px;
  text-align: center;
  line-height: 1.2;
  max-width: 800px;
}

.animated-headline {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 800;
  font-size: 44px;
  text-align: center;
  margin-bottom: 15px; /* Reduced from 20px */
  line-height: 1.2;
  max-width: 800px;
  min-height: 110px; /* Slightly reduced height */
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE/Edge */
  padding: 0 10px; /* Add some horizontal padding */
}

.simple-typing {
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE/Edge */
  font-family: inherit;
  font-weight: 800;
}

/* Styling for react-typing-effect cursor */
.typing-cursor {
  font-size: 44px;
  margin-left: 5px;
  animation: blink 0.7s step-start infinite;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

@keyframes blink {
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.subtitle {
  font-size: 18px;
  margin-bottom: 35px; /* Reduced from 50px */
  text-align: center;
}

/* ===== EmailSignup Component ===== */
.email-form {
  position: relative;
  width: 100%;
  max-width: 550px;
}

.email-input {
  width: 100%;
  padding: 20px 30px;
  border-radius: 50px;
  border: 1px solid #15141d;
  background-color: #15141d;
  color: white;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.email-input:focus ~ .signup-button {
  background-color: #2064DC;
  color: white;
}
.email-input:focus + .signup-button:hover {
  background-color: #184DAE;
}

.email-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.input-border {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 55px;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  border: 2px solid transparent;
  background: linear-gradient(90deg, #ff00d4, #00b3ff, #00f0b8, #ff00d4) border-box;
  background-size: 300% 100%;
  animation: gradient-animation 4s ease infinite;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  box-shadow:
    0 0 5px rgba(255, 0, 212, 0.7),
    0 0 8px rgba(0, 179, 255, 0.7),
    0 0 10px rgba(0, 240, 184, 0.7);
  filter: blur(1.5px);
}

.signup-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.1);
  color: #73738b;
  font-size: medium;
  border: none;
  padding: 12px 25px;
  border-radius: 30px;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.3s ease;
}

/* Make signup button blue on hover */
.signup-button:hover, .signup-button:focus {
  background-color: #2064DC;
  color: white;
}

.success-message-container {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 35px;
  max-width: 550px;
  width: 100%;
  position: relative;
  height: 150px;
}

.success-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  flex-wrap: wrap; /* Allow wrapping on very narrow screens */
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
}

.checkmark-circle {
  background-color: #3b82f6; /* blue-400 equivalent */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-shrink: 0;
}

.success-message {
  font-size: 18px;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  position: relative;
  z-index: 2;
  text-align: center;
  flex: 1;
}

.lottie-container {
  width: 300px;
  height: 280px;
  margin: -80px auto 0; /* Negative top margin for desktop */
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  position: absolute;
  z-index: 1; /* Lower z-index than the text */
  left: 0 ;
  right: 0;
  bottom: 0;
}

/* Mobile-specific adjustment for lottie container margin */
@media (max-width: 768px) {
  .lottie-container {
    margin: -175px auto 0; /* More overlap on mobile */
  }

  .success-text-container {
    padding: 0 10px; /* Add some padding on small screens */
  }

  .checkmark-circle {
    margin-right: 10px;
  }

  .success-message {
    font-size: 15px;
  }
}

@media (max-width: 320px) {
  .success-text-container {
    flex-direction: column; /* Stack vertically on very small screens */
    align-items: center;
  }
  
  .checkmark-circle {
    margin-right: 0;
    margin-bottom: 10px; /* Add space below checkmark */
  }
  
  .success-message {
    text-align: center; /* Center text when stacked */
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* ===== Animations ===== */
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-3px); /* Higher initial bounce */
  }
  60% {
    transform: translateY(-2px); /* Settles at final position */
  }
  80% {
    transform: translateY(-1px); /* Small rebound */
  }
  100% {
    transform: translateY(-2px); /* Final settled position */
  }
}

/* ===== Responsive Styles ===== */
@media (max-width: 768px) {
  h1 {
    font-size: 32px; /* Further reduced for mobile */
    flex: 1;
  }
  .subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .animated-headline {
    font-size: 32px;
    min-height: 105px;
  }
  .typing-cursor {
    font-size: 32px;
  }
  .logo {
    margin-bottom: 30px;
  }
  .digg-container {
    margin-bottom: 40px;
  }
  .input-border {
    /* Higher default opacity on mobile even without hover */
    opacity: 0.7 !important; 
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 28px;
  }
  .animated-headline {
    font-size: 28px;
    min-height: 105px;
  }
  .typing-cursor {
    font-size: 28px;
  }
  .email-input {
    padding: 16px 20px;
  }
  .signup-button {
    padding: 8px 16px;
  }
}

.particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Don't block mouse events */
  z-index: 1; /* Between the glow (z-index: 0) and the digg box (z-index: 2) */
  overflow: visible;
}

.particle {
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  transform: translate(-50%, -50%); /* Center particle */
  will-change: transform, opacity; /* Performance optimization */
}

@keyframes particleFly {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translate(
      calc(var(--x, 0) * 1px - 50px), 
      calc(var(--y, 0) * 1px - 50px)
    ) scale(0.1);
    opacity: 0;
  }
}

